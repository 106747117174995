import React from 'react'
import "./Dynamic.css"

function DynamicPage({ content }) {
  console.log("content");
  console.log(content)
  // const {content,handleContent} = useContext(DynamicContext);

  return (
    <div className={`test`} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default DynamicPage